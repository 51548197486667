import React from 'react';
import styles from './StripeAddCard.styles';
import { css } from 'aphrodite';
import { CardElement } from 'react-stripe-elements';
import PrimaryButton from '../../../components/buttons/PrimaryButton/PrimaryButton';

const StripeAddCard = (props) => (
  <div className={css(styles.addCardContainer)}>
    <div className={css(styles.stripeInputContainer)}>
      <CardElement className={'stripe-card-element'} style={{base: {fontSize: '16px'}}} />
    </div>
    <PrimaryButton
      onClick={props.submit}
      disabled={(props.userDetails.savingCardDetails) || (props.disabled)}
      title={'Confirm booking'}
      className={`${props.className}`}
      large={true}
      style={{width: '100%', marginTop: 16}}
      loading={props.loading}
    />
  </div>
);

export default StripeAddCard;
