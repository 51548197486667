import React, { Component } from "react";
import { injectStripe } from "react-stripe-elements";
import connect from "react-redux/es/connect/connect";
import {
  paymentFailed,
  receiveToken,
  SET_CARD_DETAILS_FAILED,
} from "./StripeAddCardActions";
import StripeAddCard from "./StripeAddCard";
import { makePaymentAsGuest } from "../Payment/PaymentActions";
import { PAYMENT_METHOD } from "../Payment/PaymentContainer";

class StripeAddCardContainer extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      loading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.payment.requiresScaAuth !==
        this.props.payment.requiresScaAuth &&
      !!nextProps.payment.requiresScaAuth
    ) {
      this.props.stripe
        .confirmCardPayment(
          nextProps.payment.requiresScaAuth // response.payment_intent_client_secret
        )
        .then((resp) => {
          if (resp.error) {
            // Show error in payment form
            this.props.paymentFailed(resp.error.message);
            this.setState({
              loading: false,
            });
          } else {
            // The card action has been handled
            // The PaymentIntent can be confirmed again on the server
            this.props.makePaymentAsGuest(
              null,
              resp.paymentIntent.id,
              null,
              PAYMENT_METHOD.CARD
            );
          }
        });
    }
  }

  async submit(ev) {
    this.setState({
      loading: true,
    });

    this.props.stripe
      .createPaymentMethod("card", StripeAddCard, {
        billing_details: {
          name: `${this.props.userDetails.firstName} ${this.props.userDetails.lastName}`,
          email: this.props.userDetails.email,
          phone: this.props.userDetails.mobileNumber,
        },
      })
      .then((resp) => {
        if (resp.error) {
          this.props.receiveTokenFailed();
          this.setState({ loading: false });
        }

        if (resp.paymentMethod) {
          // console.log('Received Stripe PaymentMethod:', resp.paymentMethod);
          this.props.makePaymentAsGuest(
            resp.paymentMethod.id,
            null,
            null,
            PAYMENT_METHOD.CARD
          );
        }
      });
  }

  render() {
    return (
      <StripeAddCard
        {...this.props}
        submit={this.submit}
        loading={this.state.loading}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    userDetails: state.userDetails,
    payment: state.bookingPayment,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    makePaymentAsGuest: (payment_method_id, payment_intent_id) =>
      dispatch(
        makePaymentAsGuest(
          payment_method_id,
          payment_intent_id,
          null,
          PAYMENT_METHOD.CARD
        )
      ),
    receiveToken: (token) => dispatch(receiveToken(token)),
    paymentFailed: (errorMessage) => dispatch(paymentFailed(errorMessage)),
    receiveTokenFailed: () =>
      dispatch({
        type: SET_CARD_DETAILS_FAILED,
        payload: {
          errorMessage: "Failed to add card",
        },
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectStripe(StripeAddCardContainer));
