import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import { FONTS } from '../../../styles/fonts';

const styles = StyleSheet.create({
  addCardContainer: {
    paddingTop: 12
  },
  addCardButton: {
    width: '100%',
    textAlign: 'center',
    textTransform: 'none',
    boxShadow: 'none',
    fontWeight: 'bold',
    marginTop: 16,
    height: 48
  },
  stripeInputContainer: {
    padding: 18,
    paddingLeft: 16,
    paddingRight: 16,
    background: COLORS.white,
    borderRadius: 48,
    boxShadow: '0 6px 14px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: 16
  }
});

export default styles;
