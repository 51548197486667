import React from 'react';
import PaymentContainer from '../containers/bookingStep4/Payment/PaymentContainer';
import Layout from '../containers/gatsby/layout';

const BookPaymentPage = (props) => (
  <Layout hideHeader={true} hideFooter={true} location={props.location}>
    <PaymentContainer />
  </Layout>
);

export default BookPaymentPage;



