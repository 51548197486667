import { StyleSheet } from "aphrodite";
import QUERIES from "../../../styles/queries";
import COLORS from "../../../styles/colors";
import { FONTS } from "../../../styles/fonts";

const styles = StyleSheet.create({
  root: {
    // ...
    // paddingBottom: 64,
    // minHeight: '100vh'
  },
  content: {
    maxWidth: 664,
    margin: "0 auto",
    width: "100%",
    [QUERIES.desktop]: {
      padding: 32,
      width: "calc(100% - 64px)",
    },
  },
  titleArea: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: 16,
    position: "relative",
  },
  paymentArea: {
    display: "flex",
    flexDirection: "row",
    margin: "0 auto",
    flexWrap: "wrap",
    minHeight: 360,
  },
  paymentSummaryContainer: {
    flex: 1,
    minWidth: 300,
  },
  paymentSummaryContent: {
    padding: 32,
    paddingTop: 0,
    [QUERIES.mobile]: {
      padding: 16,
    },
  },
  cardContainer: {
    flex: 1,
    minWidth: 300,
    [QUERIES.mobile]: {
      padding: 0,
    },
  },
  cardContainerContent: {
    padding: 32,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    background: COLORS.white,
    margin: "0 auto",
    borderRadius: 4,
    // border: '1px solid whitesmoke',
    boxShadow: "0 16px 40px rgba(0,0,0,0.12)",
    [QUERIES.mobile]: {
      padding: 24,
    },
  },
  paymentMethodTitle: {
    fontSize: 25,
    margin: 0,
    lineHeight: "normal",
    paddingTop: 6,
    fontFamily: FONTS.Content,
    color: COLORS.jjdGreyish,
    fontWeight: "normal",
  },
  priceRow: {
    color: COLORS.lightBlack,
    paddingBottom: 4,
  },
  cardRow: {
    color: "#bbbbbb",
    fontWeight: "bold",
  },
  confirmationText: {
    fontSize: 12,
    margin: 0,
    paddingBottom: 8,
    paddingTop: 16,
  },
  smallConfirmationText: {
    fontSize: 13,
    margin: 0,
    paddingBottom: 0,
    paddingTop: 16,
  },
  confirmList: {
    margin: 0,
    paddingBottom: 0,
    paddingTop: 24,
    paddingLeft: 0,
  },
  confirmListItem: {
    fontSize: 12,
    marginLeft: 6,
    // height: 24,
    marginBottom: 4,
    // color: COLORS.offBlack,
    fontWeight: "normal",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  confirmCheck: {
    fontSize: 13,
    marginRight: 10,
    // color: COLORS.kgDarkGreen
  },
  congestionChargeText: {
    fontSize: 13,
    marginTop: 12,
    paddingBottom: 12,
  },
  priceInfoText: {
    fontSize: 12,
    margin: 0,
  },
  priceBreakdownContainer: {
    paddingBottom: 0,
    paddingTop: 16,
  },
  acceptedCardsContainer: {
    padding: 4,
    border: "1px solid whitesmoke",
    borderRadius: 4,
    [QUERIES.mobile]: {
      border: "none",
    },
  },
  secureLabel: {
    position: "absolute",
    color: "#c1c1c1",
    fontSize: 10,
    textAlign: "center",
    bottom: 6,
    right: 6,
    background: "white",
    paddingLeft: 6,
    paddingRight: 6,
    [QUERIES.mobile]: {
      display: "none",
    },
  },
  cardImg: {
    width: 32,
    marginRight: 4,
    [QUERIES.mobile]: {
      width: 18,
    },
  },
  confirmButton: {
    width: "100%",
    textAlign: "center",
    textTransform: "none",
    boxShadow: "none",
    fontWeight: "bold",
    marginTop: 4,
    height: 48,
  },
  errorText: {
    margin: 0,
    fontSize: 12,
    color: COLORS.hotPink,
  },
  paymentFailedContainer: {
    padding: 16,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
  },
  retryActionText: {
    margin: 0,
    fontSize: 12,
    color: COLORS.kgDarkGreen,
  },
  orText: {
    color: "#bbbbbb",
    fontSize: 12,
    paddingLeft: 4,
    paddingRight: 4,
  },
  changeCardActionText: {
    margin: 0,
    fontSize: 12,
    color: COLORS.offBlack,
  },
  paymentSuccessContainer: {
    paddingTop: 12,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  doneIcon: {
    color: COLORS.kgDarkGreen,
    height: 64,
    width: 64,
    [QUERIES.mobile]: {
      textAlign: "center",
      paddingBottom: 24,
      margin: "0 auto",
    },
  },
  doneText: {
    color: COLORS.kgDarkGreen,
    fontSize: 14,
    paddingTop: 16,
    paddingLeft: 24,
    margin: 0,
    flex: 1,
    [QUERIES.mobile]: {
      padding: 0,
      flex: "0 0 100%",
    },
  },
  giveUsACallContainer: {
    textAlign: "center",
    width: "100%",
    marginTop: 64,
  },
  phoneNumber: {
    fontSize: 32,
    textDecoration: "none",
    color: COLORS.lightBlack,
    fontWeight: "bold",
    [QUERIES.desktop]: {
      fontSize: 42,
    },
  },
  bernardImg: {
    width: 60,
    height: 60,
    borderRadius: 60,
  },
  bernardImgContainer: {
    width: 60,
    height: 60,
    position: "relative",
    margin: "0 auto",
  },
  onlineCircle: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: 18,
    height: 18,
    borderRadius: 18,
    border: "2px solid white",
    background: COLORS.kgGreen,
  },
  namesContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    border: "none",
    marginTop: 32,
    overflow: "hidden",
  },
  nameInput: {
    padding: 18,
    maxWidth: 241,
    width: "100%",
    fontWeight: "normal",
    fontSize: 15,
    color: COLORS.jjdGreyish,
    opactiy: 0.5,
    margin: "20px 10px",
    border: "1px solid transparent",
    boxShadow: "0 6px 14px 0 rgba(0, 0, 0, 0.1)",
    borderRadius: 48,
  },
  helperText: {
    flex: 1,
    fontSize: 12,
    margin: 0,
    borderBottom: "1px solid whitesmoke",
    position: "relative",
    paddingBottom: 12,
    paddingLeft: 42,
  },
  helperTextArrow: {
    position: "absolute",
    fontSize: 12,
    left: 24,
  },
  bookingText: {
    marginTop: 40,
    fontSize: 15,
    fontWeight: "normal",
    color: COLORS.jjdGreyish,
  },
  stripeBtn: {
    borderRadius: 48,
    maxWidth: 340,
    margin: "50px auto 16px auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
    fontFamily: FONTS.Content,
    fontSize: 18,
  },
  paymentBtnContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "stretch",
    alignItems: "center",
    border: "none",
    background: COLORS.whiteOpaque,
  },
  cardBtn: {
    flex: 1,
    backgroundColor: "transparent",
    color: COLORS.jjdGreyish,
    borderRadius: 0,
    borderLeft: "transparent",
    borderRight: "transparent",
    borderTop: "transparent",
    borderBottom: "3px solid #eee",
    fontFamily: FONTS.Content,
    fontWeight: "normal",
    fontSize: 15,
    textTransform: "none",
    boxShadow: "none",
    [QUERIES.lessThan400]: {
      fontSize: 9,
    },
    [QUERIES.lessThan470]: {
      fontSize: 11,
    },
  },
  payCard: {},
  bankTransfer: {
    [QUERIES.desktop]: {
      marginLeft: -25,
    },
  },
  activeBtnStyle: {
    borderColor: COLORS.jjdRed,
    borderLeft: "transparent",
    borderRight: "transparent",
    borderTop: "transparent",
    borderBottom: "3px solid",
    color: COLORS.jjdRed,
    [QUERIES.desktop]: {
      zIndex: 99,
      // transition: "transform 1000ms ease-in",
      transition: "transform 3000ms ease",
    },
  },
  accountFieldContainer: {
    marginTop: 30,
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "center",
    border: "1px solid transparent",
    borderRadius: 50,
    // boxShadow: "0 6px 14px 0 rgba(0, 0, 0, 0.1)",
  },
  accountDetails: {
    width: "100%",
    maxWidth: 218,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  paypalWrapper: {
    width: "300px",
    margin: "auto",
    marginTop: "20px",
    [QUERIES.lessThan600]: {
      width: "272px",
    },
  },
  paypalBookingSuccessLoadingWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  choosePaymentMethodTitle: {
    fontSize: "22px",
    marginBottom: "10px",
  },
});

export default styles;
