import React, { Component } from "react";
import _ from "lodash";
import BookingHeader from "../../../components/booking/BookingHeader/BookingHeader";
import connect from "react-redux/es/connect/connect";
import Payment from "./Payment";
import {
  changeCard,
  setOneUseToken,
  resetPaymentErrors,
  makePaymentAsGuest,
  resetPaymentStuff,
} from "./PaymentActions";
import { navigate } from "gatsby";
import { window } from "browser-monads";
import { resetBooking } from "../../bookingStep5/BookingSuccess/BookingSuccessActions";
import Utils from "../../../library/utils";
import {
  setFirstName,
  setLastName,
  setMobileNumber,
  setEmail,
} from "../../../actions/UserDetailsActions";
import { saveLead } from "../../../actions/LeadActions";

export const PAYMENT_METHOD = {
  BANK: "bank",
  PAYPAL: "paypal",
  CARD: "card",
};

class BookingPaymentContainer extends Component {
  constructor(props) {
    super(props);
    this.activeTimeout = null;
    this.stripeApiKey =
      `${process.env.GATSBY_API_URL}` === "https://api.jjdvans.com/"
        ? "pk_live_PL3BEeH12WSDR5RDswd9C2kx00ifdZgJ77"
        : "pk_test_FxWnxNsFVLXVBiHuPeajaF2O005Xz60Iyz";
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      activePaymentMethod: PAYMENT_METHOD.CARD,
      paypalSuccessLoading: false,
    };

    this.saveMagicLeadTimeout = null;
  }

  handleActivePaymentMethod = (methodType) => {
    this.setState({
      activePaymentMethod: methodType,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.bookingPayment.guestPaymentToken &&
      !this.props.bookingPayment.guestPaymentToken
    ) {
      this.props.makePaymentAsGuest(
        null,
        null,
        null,
        this.state.activePaymentMethod
      );
    }
    if (
      nextProps.bookingPayment.paymentSuccessful &&
      !this.props.bookingPayment.paymentSuccessful
    ) {
      navigate("/successful-booking");
    }
  }

  componentDidMount() {
    this.setState({
      firstName: this.props.userDetails.firstName,
      lastName: this.props.userDetails.lastName,
      email: this.props.userDetails.email,
      mobileNumber: this.props.userDetails.mobileNumber,
    });

    this.props.resetPaymentStuff();

    if (!this.props.bookingPayment.oneUseToken) {
      this.props.setOneUseToken();
    }

    /* if (!this.props.bookingPrice.estimate) {
      navigate("/book");
    } */

    window.scrollTo(0, 0);
  }

  onChangeFirstName = (e) => {
    this.setState(
      {
        firstName: e.target.value,
      },
      () => {
        this.props.setFirstName(this.state.firstName);
      }
    );
  };

  onChangeEmail = (e) => {
    this.setState(
      {
        email: e.target.value.replace(/\s/g, ""),
      },
      () => {
        this.props.setEmail(this.state.email);

        this.saveMagicLeadTimeout && clearTimeout(this.saveMagicLeadTimeout);
        this.saveMagicLeadTimeout = setTimeout(() => {
          if (Utils.isValidEmail(this.state.email)) {
            this.props.saveLead();
          }
        }, 600);
      }
    );
  };

  onChangeLastName = (e) => {
    this.setState(
      {
        lastName: e.target.value,
      },
      () => {
        this.props.setLastName(this.state.lastName);
      }
    );
  };

  onChangeMobileNumber = (e) => {
    this.setState(
      {
        mobileNumber: e.target.value,
      },
      () => {
        this.props.setMobileNumber(this.state.mobileNumber);

        this.saveMagicLeadTimeout && clearTimeout(this.saveMagicLeadTimeout);
        this.saveMagicLeadTimeout = setTimeout(() => {
          if (Utils.isValidMobile(this.state.mobileNumber)) {
            this.props.saveLead();
          }
        }, 600);
      }
    );
  };

  finish = () => {
    if (!this.props.auth.hasEmailAndPassword) {
      this.props.openCreatePasswordModal();
    } else {
      navigate("/");
      this.props.resetBooking();
    }
  };

  changeCard = () => {
    // todo: reset hasCard info (or add 'wantsToChangeCard: true' ...
  };

  submitBookingOnBankMode = () => {
    this.props.makePaymentAsGuest(
      null,
      null,
      null,
      this.state.activePaymentMethod
    );
  };

  /* paypalPaymentDone = (paypalData) => {
    this.setState({
      paypalPaymentDetails: paypalData,
    });
  }; */

  submitBookingOnPayPalMode = (paypalData) => {
    const { firstName, lastName, email } = this.state;
    this.setState({
      paypalSuccessLoading: true,
    });
    if (_.isEmpty(firstName)) {
      this.onChangeFirstName({
        target: { value: paypalData.details.payer.name.given_name },
      });
    }
    if (_.isEmpty(lastName)) {
      this.onChangeLastName({
        target: { value: paypalData.details.payer.name.surname },
      });
    }
    if (_.isEmpty(email)) {
      this.onChangeEmail({
        target: { value: paypalData.details.payer.email_address },
      });
    }

    setTimeout(() => {
      this.props.makePaymentAsGuest(
        null,
        null,
        paypalData,
        this.state.activePaymentMethod
      );
    }, 1000);
  };

  render() {
    return (
      <>
        <BookingHeader title={"Checkout"} backUrl={"/set-arrival-time"} />
        <Payment
          changeCard={this.changeCard}
          {...this.props}
          stripeApiKey={this.stripeApiKey}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          email={this.state.email}
          mobileNumber={this.state.mobileNumber}
          activePaymentMethod={this.state.activePaymentMethod}
          onChangeFirstName={this.onChangeFirstName}
          onChangeLastName={this.onChangeLastName}
          onChangeEmail={this.onChangeEmail}
          onChangeMobileNumber={this.onChangeMobileNumber}
          handleActivePaymentMethod={this.handleActivePaymentMethod}
          payButtonDisabled={
            Utils.isBlank(this.state.firstName) ||
            Utils.isBlank(this.state.lastName) ||
            !Utils.isValidEmail(this.state.email) ||
            !Utils.isValidMobile(this.state.mobileNumber)
          }
          submitBookingOnBankMode={this.submitBookingOnBankMode}
          submitBookingOnPayPalMode={this.submitBookingOnPayPalMode}
          // paypalPaymentDetails={this.state.paypalPaymentDetails}

          paypalSuccessLoading={this.state.paypalSuccessLoading}
        />
      </>
    );
  }

  componentWillUnmount() {
    this.activeTimeout && clearTimeout(this.activeTimeout);
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    bookingDistance: state.bookingDistance,
    bookingGeneral: state.bookingGeneral,
    bookingAddresses: state.bookingAddresses,
    bookingPrice: state.bookingPrice,
    bookingPayment: state.bookingPayment,
    userDetails: state.userDetails,
    bookingPricesCustomChoices: state.bookingPricesCustomChoices,
    selectedVanSizeIndex: state.bookingGeneral.vanSizeIndex || 0,
  };
}

const mapDispatchToProps = {
  makePaymentAsGuest: makePaymentAsGuest,
  changeCard: changeCard,
  resetBooking: resetBooking,
  setOneUseToken: setOneUseToken,
  resetPaymentErrors: resetPaymentErrors,
  resetPaymentStuff: resetPaymentStuff,
  setFirstName: setFirstName,
  setLastName: setLastName,
  setEmail: setEmail,
  setMobileNumber: setMobileNumber,
  saveLead: saveLead,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingPaymentContainer);
